<template>
  <page-header-wrapper :loading="loading">
  <div class="app-container home" >
    <div class="spin-load" v-if="showLoading">
      <a-spin :spinning="showLoading">
      </a-spin>
    </div>
    <div v-if="!showLoading">
      <div class="app-container-card" v-if="!hasAccount" >
        <a-row :gutter="20">
          <a-col :span="6" :offset="8">
            <a-card shadow="hover">
              <div style="padding: 14px;">
                <a-divider>客服账户申请</a-divider>
                <div class="content">客服服务与系统深度集成、便捷、优惠</div>
                <div class="bottom">
                  <a-button type="text" class="button" @click="handleAddAccount">申请客服账户</a-button>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div  v-if="hasAccount" class="sms-account-page">
        <a-card :bordered="false" :loading="loading">
          <a-descriptions bordered   size="middle" layout="vertical">
            <template slot="title">
              <div>
                <div style="float:left; margin-left: 10px;">
                  账户信息
                </div>
                <div style="float: right">
                  <a-button type="primary"  @click="handleShowAccount" style="margin-right: 20px;margin-bottom: 10px">客服管理平台</a-button>
                </div>
              </div>

            </template>
            <a-descriptions-item label="商户账号"><a-badge status="processing" :text="accountInfo.account" /></a-descriptions-item>
            <a-descriptions-item label="商户编号"><a-badge status="processing" :text="accountInfo.seller_code" /></a-descriptions-item>
            <a-descriptions-item label="商户名称"><a-badge status="processing" :text="accountInfo.seller_name" /></a-descriptions-item>
            <a-descriptions-item label="最大客服人员数"><a-badge status="error" :text="accountInfo.max_kefu_num" /></a-descriptions-item>
            <a-descriptions-item label="最大客服分组数"><a-badge status="error" :text="accountInfo.max_group_num" /></a-descriptions-item>
            <a-descriptions-item label="账号有效期"><a-badge status="error" :text="accountInfo.valid_time" /></a-descriptions-item>
          </a-descriptions>

        </a-card>


      </div>
    </div>
  </div>
  <!--申请开通商户账户-->
    <a-drawer :visible.sync ="accountOpen" width="600"  placement="right" @close="cancelAccount" >
      <a-divider orientation="left">
        <b>申请开通账户</b>
      </a-divider>
      <a-form-model ref="accountForm" :model="accountForm" :rules="accountRules" laba-width="80px">
        <a-form-model-item label="商户名称" prop="seller_name">
          <a-input v-model="accountForm.seller_name" placeholder="请输入商户名称" />
        </a-form-model-item>
        <a-form-model-item label="账号" prop="account">
          <a-input v-model="accountForm.account" placeholder="请输入账号" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="seller_password">
          <a-input v-model="accountForm.seller_password" placeholder="请输入密码" />
        </a-form-model-item>
        <a-form-model-item label="接入域名" prop="access_url">
          <a-input v-model="accountForm.access_url"  placeholder="请输入接入域名" />
        </a-form-model-item>
      </a-form-model>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitAccountForm">创建</a-button>
          <a-button @click="cancelAccount">取 消</a-button>
        </a-space>
      </div>
    </a-drawer>
  </page-header-wrapper>
</template>

<script>
import {getSeller, addSeller,getSellerSignUrl,openCustomerSetting } from '@/api/customerservice/seller'
import IndexDatePicker from '@/components/IndexDatePicker'
export default {
  name: "OverviewIndex",
  components: {
    IndexDatePicker
  },
  data() {
    return {
      showLoading: true,
      accountInfo:{},
      hasAccount: false,
      loading: false,
      accountOpen:false,
      accountForm:{},
      accountRules: {
        seller_name: [
          { required: true, message: "商户名称不能为空", trigger: "blur" }
        ],
        account: [
          { required: true, message: "账号不能为空", trigger: "blur" }
        ],
        seller_password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ],
        access_url: [
          { required: true, message: "接入域名不能为空", trigger: "blur" }
        ]
      },
    };
  },
  created() {
    //获取客服账户信息
    this.getAccountInfo()
  },
  mounted() {

  },
  methods: {
    handleAddAccount(){
      this.accountReset();
      this.accountOpen = true
    },
    submitAccountForm(){
      this.$refs["accountForm"].validate(valid => {
        if (valid) {
          addSeller(this.accountForm).then(response => {
           if(response){
             this.accountOpen = false;
             this.$message.success("申请成功");
             this.getAccountInfo();
           }
          });
        }
      });
    },
    cancelAccount(){
      this.accountOpen = false;
      this.accountReset();
    },
    accountReset() {
      this.accountForm = {
        seller_name: null,
        account: null,
        seller_password: null,
        access_url: null
      };
      this.$nextTick(() => {
        if(this.$refs["accountForm"]){
          this.$refs["accountForm"].resetFields()
        }
      })

    },
    getAccountInfo(){
      this.loading = true
      getSeller().then(response =>{
        this.accountInfo = response.data
        if(this.accountInfo.account){
          this.hasAccount = true;
        }
        this.showLoading = false
        this.loading = false
      })
    },
    handleShowAccount(){
        window.open(process.env.VUE_APP_BASE_API+"/customer/seller/open-customer-setting","_blank")
    },

  }
};
</script>

<style  lang="less">
.home {
  .row-class{
    padding: 10px;
  }
  .app-container-card{
    margin-top: 10em;
    .bottom {
      margin-top: 13px;
      line-height: 12px;
      text-align: center;
    }
    .content{
      font-size: 12px;
      text-align: center;
    }
  }
}
.sms-account-page {
  .ant-statistic-content {
    color: #0081ff;
    text-align: center;
    font-weight: 400;
  }
}
.spin-load {
  text-align: center;
}
</style>
